import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';  
import Demo from './views/Demo.vue'; 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // For popstate navigations (e.g., browser back button)
    } else {
      return { top: 0 }; // Always scroll to top for new navigations
    }
  },
});

export default router;