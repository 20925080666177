<template>
    <section class="about-us">
      <h2>About Mosaique</h2>
      <p>
        Mosaique is an AI-powered survey platform designed to help businesses get precise, targeted answers
        from different audience segments. We empower decision-making with detailed analysis.
      </p>
    </section>
  </template>

  <script>
  export default {
    name: 'AboutUsComponent',
  }
  </script>
  <style scoped>
  .about-us {
    padding: 50px;
    background-color: #fff;
    text-align: center;
  }
  
  .about-us h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .about-us p {
    font-size: 1.2em;
  }
  </style>