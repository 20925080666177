<template>
  <div class="demo-page">
    <!-- Sticky Header -->
    <StickyHeaderComponent />

    <!-- Campaign Questionnaire -->
    <div class="questionnaire-container">
      <h1 class="title">Campaign Questionnaire</h1>

        <!-- Section 1: Campaign Type -->
        <div class="question-section">
          <h2 :class="{ 'error': hasErrors.campaignType }">
            1. What type of campaign is this? <span v-if="hasErrors.campaignType">*</span>
          </h2>
          <div class="question-buttons">
            <button v-for="type in campaignTypes" :key="type" @click="setCampaignType(type)"
                    :class="{'selected': selectedCampaignType === type}">
              {{ type }}
            </button>
          </div>
        </div>

              <!-- Section 2: Objectives -->
        <div class="question-section">
        <h2 :class="{ 'error': hasErrors.objectives }">
          2. What are the main objective(s) of your marketing campaign? <span v-if="hasErrors.objectives">*</span>
          </h2>
          <div class="question-buttons">
          <button v-for="objective in objectives" :key="objective" @click="toggleObjective(objective)" 
                  :class="{'selected': isObjectiveSelected(objective)}">
            {{ objective }}
          </button>
        </div>
      </div>


      <!-- Section 3: Target Audience Selection -->
      <div class="question-section">
        <h2 :class="{ error: hasErrors.targetAudience }">
          3. Who is your target audience for this campaign?
          <span v-if="hasErrors.targetAudience">*</span>
        </h2>

        <div class="dropdown-group">
          <!-- Dropdown for Age -->
          <div>
            <label for="age">Age</label>
            <select v-model="targetAudience.age" id="age">
              <option disabled value="">Please select an age group</option>
              <option>18-24</option>
              <option>25-35</option>
              <option>36-45</option>
              <option>46-55</option>
              <option>56-65</option>
              <option>65+</option>
            </select>
          </div>

          <!-- Dropdown for Gender -->
          <div>
            <label for="gender">Gender</label>
            <select v-model="targetAudience.gender" id="gender">
              <option disabled value="">Please select an gender group</option>
              <option>Male</option>
              <option>Female</option>
              <option>Randomize</option>
            </select>
          </div>

          <!-- Dropdown for Household Size -->
          <div>
            <label for="householdSize">Household Size</label>
            <select v-model="targetAudience.householdSize" id="householdSize">
              <option disabled value="">Please select a household size</option>
              <option>1-2</option>
              <option>3-4</option>
              <option>5+</option>
            </select>
          </div>

          <!-- Dropdown for Marital Status -->
          <div>
            <label for="maritalStatus">Marital Status</label>
            <select
              v-model="targetAudience.maritalStatus"
              id="maritalStatus"
            >
              <option disabled value="">Please select marital status</option>
              <option>Single</option>
              <option>Married</option>
              <option>Divorced</option>
              <option>Widowed</option>
            </select>
          </div>

          <!-- Dropdown for Family Lifecycle -->
          <div>
            <label for="familyLifecycle">Family Lifecycle</label>
            <select
              v-model="targetAudience.familyLifecycle"
              id="familyLifecycle"
            >
              <option disabled value="">Please select family lifecycle</option>
              <option>No children</option>
              <option>Youngest child under 6</option>
              <option>Youngest child 6 or over</option>
              <option>Youngest child over 12</option>
              <option>No children under 18</option>
            </select>
          </div>

          <!-- Dropdown for Home Ownership -->
          <div>
            <label for="homeOwnership">Home Ownership</label>
            <select
              v-model="targetAudience.homeOwnership"
              id="homeOwnership"
            >
              <option disabled value="">Please select home ownership</option>
              <option>Own home outright</option>
              <option>Own home with mortgage</option>
              <option>Rent apartment</option>
              <option>Rent house/townhouse</option>
              <option>Rent room in house</option>
              <option>Live with parents or relatives</option>
              <option>Senior living facility</option>
              <option>Mobile home</option>
              <option>Homeless</option>
            </select>
          </div>

          <!-- Dropdown for Income -->
          <div>
            <label for="income">Income</label>
            <select v-model="targetAudience.income" id="income">
              <option disabled value="">Please select income range</option>
              <option>Below $10,000</option>
              <option>$10,000-$29,999</option>
              <option>$30,000-$59,999</option>
              <option>$60,000-$99,999</option>
              <option>$100,000-$149,999</option>
              <option>$150,000-$299,999</option>
              <option>$300,000+</option>
            </select>
          </div>

          <!-- Dropdown for Education -->
          <div>
            <label for="education">Education</label>
            <select v-model="targetAudience.education" id="education">
              <option disabled value="">Please select education level</option>
              <option>Grade school or less</option>
              <option>Some high school</option>
              <option>High School Graduate</option>
              <option>Some college</option>
              <option>Bachelor's Degree</option>
              <option>Advanced Degrees</option>
            </select>
          </div>

          <!-- Dropdown for Region -->
          <div>
            <label for="region">Region</label>
            <select v-model="targetAudience.region" id="region">
              <option disabled value="">Please select region</option>
              <option>Pacific</option>
              <option>West North Central</option>
              <option>West South Central</option>
              <option>East North Central</option>
              <option>East South Central</option>
              <option>South Atlantic</option>
              <option>Middle Atlantic</option>
            </select>
          </div>

          <!-- Dropdown for City Size -->
          <div>
            <label for="citySize">City Size</label>
            <select v-model="targetAudience.citySize" id="citySize">
              <option disabled value="">Please select city size</option>
              <option>Under 20k</option>
              <option>20k-50k</option>
              <option>50k-100k</option>
              <option>100k-250k</option>
              <option>250k-500k</option>
              <option>500k-1m</option>
              <option>1m-4m</option>
              <option>Over 4m</option>
            </select>
          </div>

          <!-- Dropdown for Urbancity -->
          <div>
            <label for="urbancity">Urbanicity</label>
            <select v-model="targetAudience.urbancity" id="urbancity">
              <option disabled value="">Please select urbanicity</option>
              <option>Urban</option>
              <option>Suburban</option>
              <option>Rural</option>
              <option>Metro Mix</option>
              <option>Town</option>
            </select>
          </div>
        </div>
      </div>

            <!-- Section 3: What key message or value proposition do you plan to communicate? -->
            <div class="question-section">
              <h2 :class="{ 'error': hasErrors.keyValue }">
                      4. What key message or value proposition do you plan to communicate? <span v-if="hasErrors.keyValue">*</span>
                  </h2>
                  <div class="question-buttons">
                      <button v-for="keyvalue in keyValues" :key="keyvalue" @click="setKeyValue(keyvalue)"
                              :class="{'selected': selectedKeyValue === keyvalue}">
                          {{ keyvalue }}
                      </button>
                      <div v-if="selectedKeyValue === 'Other'" class="other-input">
                          <input type="text" v-model="customKeyValue" maxlength="200" placeholder="Enter your key value (200 characters max)" />
                      </div>
                  </div>
              </div>


      

            <!-- Section 4 -->
            <div class="question-section">
              <h2 :class="{ error: hasErrors.marketingChannels }">
                5. Which marketing channels do you intend to use and why?
                <span v-if="hasErrors.marketingChannels">*</span>
              </h2>
              <div class="question-buttons">
                <button
                  v-for="channel in marketingChannels"
                  :key="channel"
                  @click="setMarketingChannel(channel)"
                  :class="{ selected: selectedMarketingChannel === channel }"
                >
                  {{ channel }}
                </button>
                <!-- Display input for 'Other' if selected -->
                <div v-if="selectedMarketingChannel === 'Other'" class="other-input">
                  <input
                    type="text"
                    v-model="customMarketingChannel"
                    maxlength="200"
                    placeholder="Please specify other marketing channel"
                  />
                </div>
              </div>
            </div>

      <!-- Section 5: How long is the campaign expected to run? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.campaignDuration }">
          6. How long is the campaign expected to run? <span v-if="hasErrors.campaignDuration">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="duration in campaignDuration" :key="duration" @click="setCampaignDuration(duration)" 
                  :class="{'selected': selectedCampaignDuration === duration}">
            {{ duration }}
          </button>
        </div>
      </div>

      

      <!-- Section 6: Metrics -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.successMetrics }">
            7. What metrics will you use to measure the success of this campaign? <span v-if="hasErrors.successMetrics">*</span>
          </h2>
          <div class="question-buttons">
          <button v-for="metric in successMetrics" :key="metric" @click="toggleMetric(metric)" 
                  :class="{'selected': isMetricSelected(metric)}">
            {{ metric }}
          </button>
        </div>
      </div>

      <!-- Section 7: How does this campaign align with your overall brand strategy? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.brandStrategy }">
            8. How does this campaign align with your overall brand strategy? <span v-if="hasErrors.brandStrategy">*</span>
          </h2>
          <div class="question-buttons">
          <button v-for="strategy in brandStrategies" :key="strategy" @click="setBrandStrategy(strategy)"
                  :class="{'selected': selectedBrandStrategy === strategy}">
            {{ strategy }}
          </button>
          <div v-if="selectedBrandStrategy === 'Other'" class="other-input">
            <input type="text" v-model="customBrandStrategy" maxlength="200" placeholder="Enter your brand strategy (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 8: What innovative or creative elements are you planning to incorporate? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.creativeElements }">
          9. What innovative or creative elements are you planning to incorporate? <span v-if="hasErrors.creativeElements">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="element in creativeElements" :key="element" @click="toggleElement(element)"
                  :class="{'selected': isElementSelected(element)}">
            {{ element }}
          </button>
          <div v-if="isElementSelected('Other')" class="other-input">
            <input type="text" v-model="customElement" maxlength="200" placeholder="Enter custom element (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 9: Are there any new technologies or tools you plan to utilize? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.technologies }">
          10. Are there any new technologies or tools you plan to utilize in this campaign? <span v-if="hasErrors.technologies">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="technology in technologies" :key="technology" @click="toggleTechnology(technology)"
                  :class="{'selected': isTechnologySelected(technology)}">
            {{ technology }}
          </button>
          <div v-if="isTechnologySelected('Other')" class="other-input">
            <input type="text" v-model="customTechnology" maxlength="200" placeholder="Enter custom technology (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 10: How do you plan to ensure consistency across different marketing touchpoints? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.marketingConsistency }">
          11. How do you plan to ensure consistency across different marketing touchpoints? <span v-if="hasErrors.marketingConsistency">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="consistency in marketingConsistency" :key="consistency" @click="setConsistency(consistency)"
                  :class="{'selected': selectedConsistency === consistency}">
            {{ consistency }}
          </button>
          <div v-if="selectedConsistency === 'Other'" class="other-input">
            <input type="text" v-model="customConsistency" maxlength="200" placeholder="Enter your consistency strategy (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 11: What is your estimated budget for this campaign? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.budget }">
          12. What is your estimated budget for this campaign? <span v-if="hasErrors.budget">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="budget in budgetRange" :key="budget" @click="setBudget(budget)"
                  :class="{'selected': selectedBudget === budget}">
            {{ budget }}
          </button>
        </div>
      </div>

      <!-- Section 12: How does this campaign differ from your previous marketing efforts? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.previousCampaignDiff }">
          13. How does this campaign differ from your previous marketing efforts? <span v-if="hasErrors.previousCampaignDiff">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="difference in previousCampaignDiff" :key="difference" @click="setPreviousCampaignDiff(difference)"
                  :class="{'selected': selectedPreviousCampaignDiff === difference}">
            {{ difference }}
          </button>
          <div v-if="selectedPreviousCampaignDiff === 'Other'" class="other-input">
            <input type="text" v-model="customPreviousCampaignDiff" maxlength="200" placeholder="Enter your difference (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 13: Are there any market trends or consumer behaviors influencing your campaign strategy? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.marketTrend }">
          14. Are there any market trends or consumer behaviors influencing your campaign strategy? <span v-if="hasErrors.marketTrend">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="trend in marketTrends" :key="trend" @click="setMarketTrend(trend)"
                  :class="{'selected': selectedMarketTrend === trend}">
            {{ trend }}
          </button>
          <div v-if="selectedMarketTrend === 'Other'" class="other-input">
            <input type="text" v-model="customMarketTrend" maxlength="200" placeholder="Enter your market trend (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 14: What primary tone or language style will your campaign use? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.toneStyle }">
          15. What primary tone or language style will your campaign use? <span v-if="hasErrors.toneStyle">*</span>
        </h2>
          <div class="question-buttons">
          <button v-for="tone in toneStyles" :key="tone" @click="setToneStyle(tone)"
                  :class="{'selected': selectedToneStyle === tone}">
            {{ tone }}
          </button>
          <div v-if="selectedToneStyle === 'Other'" class="other-input">
            <input type="text" v-model="customToneStyle" maxlength="200" placeholder="Enter your tone style (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 15: Why did you choose this particular tone for your campaign? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.toneReason }">
          16. Why did you choose this particular tone for your campaign? <span v-if="hasErrors.toneReason">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="reason in toneReasons" :key="reason" @click="setToneReason(reason)"
                  :class="{'selected': selectedToneReason === reason}">
            {{ reason }}
          </button>
          <div v-if="selectedToneReason === 'Other'" class="other-input">
            <input type="text" v-model="customToneReason" maxlength="200" placeholder="Enter your reason (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 16: Email Address -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.email }">
          17. Please enter your email address: <span v-if="hasErrors.email">*</span>
        </h2>
        <div class="other-input">
          <input type="email" v-model="email" maxlength="200" placeholder="Enter your email address" />
        </div>
      </div>
      
      <!-- Submit Button -->
      <div class="submit-container">
        <button class="submit-button" @click="submitQuestionnaire">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import StickyHeaderComponent from '../components/Header-Sticky.vue';

export default {
  components: {
    StickyHeaderComponent,
  },
  data() {
    return {
      // Campaign Types
      campaignTypes: [
        'Brand awareness campaigns',
        'Product launch campaigns',
        'Lead generation campaigns',
        'Content marketing campaigns',
        'Social media campaigns', 
        'Retargeting campaigns',
        'Seasonal or holiday campaigns',
        'Referral campaigns',
        'User-generated content campaigns',
        'Guerrilla marketing campaigns',
        'Event marketing campaigns',
        'Social media campaigns',
        'Email marketing campaigns',
        'Cause marketing campaigns',
        'Loyalty program campaigns',
        'Influencer marketing campaigns',
      ],
      selectedCampaignType: '',

      // Objectives
      objectives: [
        'Increase brand awareness',
        'Generate leads',
        'Drive sales',
        'Improve customer retention',
        'Launch a new product/service',
        'Enhance brand reputation',
        'Enter a new market',
        'Reposition the brand'
      ],
      selectedObjectives: [],

      // Target Audience Object
      targetAudience: {
        age: '',
        gender: '',
        householdSize: '',
        maritalStatus: '',
        familyLifecycle: '',
        homeOwnership: '',
        income: '',
        education: '',
        region: '',
        citySize: '',
        urbancity: '',
      },

        // Key Value
        keyValues: [
        'Product Quality',
        'Cost savings',
        'Convenience',
        'Innovation',
        'Exclusivity',
        'Sustainability',
        'Customer service',
        'Lifestyle enhancement',
        'Problem-solving',
        'Other'
      ],
      selectedKeyValue: '',
      customKeyValue: '',

      // Marketing Channels
      marketingChannels: [
        'Social media',
        'Email marketing',
        'Content marketing',
        'Paid advertising',
        'Influencer marketing',
        'Traditional media (TV, radio, print)',
        'Event marketing',
        'Search engine optimization (SEO)',
        'Direct mail',
        'Other',
      ],
      selectedMarketingChannel: '',
      customMarketingChannel: '',

      // Campaign Duration
      campaignDuration: [
        'Less than 1 month',
        '1-3 months',
        '3-6 months',
        '6-12 months',
        'Over 12 months',
        'Ongoing campaign',
      ],
      selectedCampaignDuration: '',

      // Success Metrics
      successMetrics: [
        'Return on Investment (ROI)',
        'Conversion rate',
        'Engagement rate',
        'Website traffic',
        'Sales revenue',
        'Lead generation',
        'Brand recall',
        'Customer Lifetime Value (CLV)',
        'Net Promoter Score (NPS)',
        'Other',
      ],
      selectedMetrics: [],
      customMetric: '',

      // Brand Strategies
      brandStrategies: [
        'Reinforces existing brand image',
        'Introduces a new brand direction',
        'Expands to new market segments',
        'Strengthens customer relationships',
        'Differentiates from competitors',
        'Highlights corporate social responsibility',
        'Showcases innovation',
        'Other',
      ],
      selectedBrandStrategy: '',
      customBrandStrategy: '',

      // Creative Elements
      creativeElements: [
        'Interactive experiences',
        'User-generated content',
        'Augmented or Virtual Reality',
        'Artificial Intelligence',
        'Personalization at scale',
        'Storytelling',
        'Gamification',
        'Live streaming',
        'Other',
      ],
      selectedElements: [],
      customElement: '',

      // Technologies
      technologies: [
        'AI-powered content creation',
        'Chatbots or conversational AI',
        'Virtual or Augmented Reality',
        'Blockchain for ad verification',
        'Voice search optimization',
        '5G technology',
        'Internet of Things (IoT) devices',
        'Advanced analytics platforms',
        'Other',
      ],
      selectedTechnologies: [],
      customTechnology: '',

      // Marketing Consistency
      marketingConsistency: [
        'Centralized brand guidelines',
        'Cross-team collaboration tools',
        'Regular team meetings',
        'Automated content distribution',
        'Integrated marketing platforms',
        'Third-party agency management',
        'Content calendars',
        'Other',
      ],
      selectedConsistency: '',
      customConsistency: '',

      // Budget
      budgetRange: [
        'Under $10,000',
        '$10,000 - $50,000',
        '$50,000 - $100,000',
        '$100,000 - $500,000',
        '$500,000 - $1 million',
        'Over $1 million',
        'Prefer not to disclose',
      ],
      selectedBudget: '',

      // Previous Campaign Difference
      previousCampaignDiff: [
        'Targets a new audience',
        'Uses new channels',
        'Incorporates new technologies',
        'Has a larger budget',
        'Focuses on different messaging',
        'Involves more cross-team collaboration',
        'Emphasizes different metrics',
        'Other',
      ],
      selectedPreviousCampaignDiff: '',
      customPreviousCampaignDiff: '',

      // Market Trends
      marketTrends: [
        'Increased focus on sustainability',
        'Growing importance of social responsibility',
        'Shift towards mobile-first experiences',
        'Rise of voice search and smart speakers',
        'Demand for more personalized experiences',
        'Preference for authentic user-generated content',
        'Increased privacy concerns',
        'Other',
      ],
      selectedMarketTrend: '',
      customMarketTrend: '',

      // Tone Styles
      toneStyles: [
        'Humorous/Playful',
        'Emotional/Sentimental',
        'Formal/Professional',
        'Casual/Conversational',
        'Inspirational/Motivational',
        'Educational/Informative',
        'Provocative/Controversial',
        'Urgent/Time-sensitive',
        'Luxurious/Sophisticated',
        'Minimalist/Straightforward',
        'Empathetic/Understanding',
        'Authoritative/Expert',
        'Nostalgic/Retro',
        'Futuristic/Innovative',
        'Other',
      ],
      selectedToneStyle: '',
      customToneStyle: '',

      // Tone Reasons
      toneReasons: [
        'Aligns with brand personality',
        'Resonates with target audience',
        'Differentiates from competitors',
        'Suits the product/service being marketed',
        'Fits the chosen marketing channels',
        'Addresses current market trends',
        'Reflects cultural context',
        'Supports campaign objectives',
        'Based on successful past campaigns',
        'Other',
      ],
      selectedToneReason: '',
      customToneReason: '',

      hasErrors: {
        campaignType: false,
        objectives: false,
        targetAudience: false,
        marketingChannels: false,
        campaignDuration: false,
        successMetrics: false,
        brandStrategy: false,
        creativeElements: false,
        technologies: false,
        marketingConsistency: false,
        budget: false,
        previousCampaignDiff: false,
        marketTrend: false,
        toneStyle: false,
        toneReason: false,
        email: false,
      },
    };
  },
  methods: {
    // Section 1: Campaign Type
    setCampaignType(type) {
      this.selectedCampaignType = type;
    },

    // Section 2: Objectives
    toggleObjective(objective) {
      const index = this.selectedObjectives.indexOf(objective);
      if (index > -1) {
        this.selectedObjectives.splice(index, 1);
      } else {
        this.selectedObjectives.push(objective);
      }
    },
    isObjectiveSelected(objective) {
      return this.selectedObjectives.includes(objective);
    },
    setKeyValue(keyvalue) {
      this.selectedKeyValue = keyvalue;
      if (keyvalue !== 'Other') {
        this.customKeyValue = '';
      }
    },

    // Section 4: Marketing Channels
    setMarketingChannel(channel) {
      this.selectedMarketingChannel = channel;
      if (channel !== 'Other') {
        this.customMarketingChannel = '';
      }
    },

    // Section 5: Campaign Duration
    setCampaignDuration(duration) {
      this.selectedCampaignDuration = duration;
    },

    // Section 6: Success Metrics
    toggleMetric(metric) {
      const index = this.selectedMetrics.indexOf(metric);
      if (index > -1) {
        this.selectedMetrics.splice(index, 1);
      } else {
        this.selectedMetrics.push(metric);
      }
    },
    isMetricSelected(metric) {
      return this.selectedMetrics.includes(metric);
    },

    // Section 7: Brand Strategy
    setBrandStrategy(strategy) {
      this.selectedBrandStrategy = strategy;
      if (strategy !== 'Other') {
        this.customBrandStrategy = '';
      }
    },

    // Section 8: Creative Elements
    toggleElement(element) {
      const index = this.selectedElements.indexOf(element);
      if (index > -1) {
        this.selectedElements.splice(index, 1);
      } else {
        this.selectedElements.push(element);
      }
    },
    isElementSelected(element) {
      return this.selectedElements.includes(element);
    },

    // Section 9: Technologies
    toggleTechnology(technology) {
      const index = this.selectedTechnologies.indexOf(technology);
      if (index > -1) {
        this.selectedTechnologies.splice(index, 1);
      } else {
        this.selectedTechnologies.push(technology);
      }
    },
    isTechnologySelected(technology) {
      return this.selectedTechnologies.includes(technology);
    },

    // Section 10: Marketing Consistency
    setConsistency(consistency) {
      this.selectedConsistency = consistency;
      if (consistency !== 'Other') {
        this.customConsistency = '';
      }
    },

    // Section 11: Budget
    setBudget(budget) {
      this.selectedBudget = budget;
    },

    // Section 12: Previous Campaign Difference
    setPreviousCampaignDiff(difference) {
      this.selectedPreviousCampaignDiff = difference;
      if (difference !== 'Other') {
        this.customPreviousCampaignDiff = '';
      }
    },

    // Section 13: Market Trends
    setMarketTrend(trend) {
      this.selectedMarketTrend = trend;
      if (trend !== 'Other') {
        this.customMarketTrend = '';
      }
    },

    // Section 14: Tone Style
    setToneStyle(tone) {
      this.selectedToneStyle = tone;
      if (tone !== 'Other') {
        this.customToneStyle = '';
      }
    },

    // Section 15: Tone Reason
    setToneReason(reason) {
      this.selectedToneReason = reason;
      if (reason !== 'Other') {
        this.customToneReason = '';
      }
    },

    // Submit the Questionnaire
    async submitQuestionnaire() {
      let hasError = false;

      // Validate Section 1: Campaign Type
      if (!this.selectedCampaignType) {
        this.hasErrors.campaignType = true;
        hasError = true;
        console.log('Error in Campaign Type: No campaign type selected');
      } else {
        this.hasErrors.campaignType = false;
      }

      // Validate Section 2: Objectives
      if (this.selectedObjectives.length === 0) {
        this.hasErrors.objectives = true;
        hasError = true;
        console.log('Error in Objectives: No objectives selected');
      } else {
        this.hasErrors.objectives = false;
      }

      // Validate Section 3: Target Audience
      if (!this.targetAudience.age || !this.targetAudience.gender) {
        this.hasErrors.targetAudience = true;
        hasError = true;
        console.log(
          'Error in Target Audience: Age and Gender are required'
        );
      } else {
        this.hasErrors.targetAudience = false;
      }

      // Validate Section 4: Marketing Channels
      if (
        !this.selectedMarketingChannel &&
        !this.customMarketingChannel
      ) {
        this.hasErrors.marketingChannels = true;
        hasError = true;
        console.log('Error in Marketing Channels: No channel selected');
      } else {
        this.hasErrors.marketingChannels = false;
      }
      // Validate Section 3: KEY VALUE
      if (!this.selectedKeyValue && !this.customKeyValue) {
        this.hasErrors.keyValue = true;
        hasError = true;
        console.log('Error in Key Value: No key value selected');
        } else {
            this.hasErrors.keyValue = false;
        }
      // Validate Section 5: Campaign Duration
      if (!this.selectedCampaignDuration) {
        this.hasErrors.campaignDuration = true;
        hasError = true;
        console.log('Error in Campaign Duration: No duration selected');
      } else {
        this.hasErrors.campaignDuration = false;
      }

      // Validate Section 6: Success Metrics
      if (this.selectedMetrics.length === 0) {
        this.hasErrors.successMetrics = true;
        hasError = true;
        console.log('Error in Success Metrics: No metrics selected');
      } else {
        this.hasErrors.successMetrics = false;
      }

      // Validate Section 7: Brand Strategy
      if (
        !this.selectedBrandStrategy &&
        !this.customBrandStrategy
      ) {
        this.hasErrors.brandStrategy = true;
        hasError = true;
        console.log('Error in Brand Strategy: No strategy selected');
      } else {
        this.hasErrors.brandStrategy = false;
      }

      // Validate Section 8: Creative Elements
      if (
        this.selectedElements.length === 0 &&
        !this.customElement
      ) {
        this.hasErrors.creativeElements = true;
        hasError = true;
        console.log('Error in Creative Elements: No elements selected');
      } else {
        this.hasErrors.creativeElements = false;
      }

      // Validate Section 9: Technologies
      if (
        this.selectedTechnologies.length === 0 &&
        !this.customTechnology
      ) {
        this.hasErrors.technologies = true;
        hasError = true;
        console.log('Error in Technologies: No technologies selected');
      } else {
        this.hasErrors.technologies = false;
      }

      // Validate Section 10: Marketing Consistency
      if (
        !this.selectedConsistency &&
        !this.customConsistency
      ) {
        this.hasErrors.marketingConsistency = true;
        hasError = true;
        console.log(
          'Error in Marketing Consistency: No consistency plan selected'
        );
      } else {
        this.hasErrors.marketingConsistency = false;
      }

      // Validate Section 11: Budget
      if (!this.selectedBudget) {
        this.hasErrors.budget = true;
        hasError = true;
        console.log('Error in Budget: No budget selected');
      } else {
        this.hasErrors.budget = false;
      }

      // Validate Section 12: Previous Campaign Difference
      if (
        !this.selectedPreviousCampaignDiff &&
        !this.customPreviousCampaignDiff
      ) {
        this.hasErrors.previousCampaignDiff = true;
        hasError = true;
        console.log(
          'Error in Previous Campaign Difference: No difference selected'
        );
      } else {
        this.hasErrors.previousCampaignDiff = false;
      }

      // Validate Section 13: Market Trend
      if (
        !this.selectedMarketTrend &&
        !this.customMarketTrend
      ) {
        this.hasErrors.marketTrend = true;
        hasError = true;
        console.log('Error in Market Trend: No trend selected');
      } else {
        this.hasErrors.marketTrend = false;
      }

      // Validate Section 14: Tone Style
      if (!this.selectedToneStyle && !this.customToneStyle) {
        this.hasErrors.toneStyle = true;
        hasError = true;
        console.log('Error in Tone Style: No tone style selected');
      } else {
        this.hasErrors.toneStyle = false;
      }

      // Validate Section 15: Tone Reason
      if (!this.selectedToneReason && !this.customToneReason) {
        this.hasErrors.toneReason = true;
        hasError = true;
        console.log('Error in Tone Reason: No reason selected');
      } else {
        this.hasErrors.toneReason = false;
      }
      if (!this.email) {
        this.hasErrors.email = true;
        hasError = true;
        console.log('Error in Email Address: No email address provided');
      } else {
        this.hasErrors.email = false;
      }

      // Check if there are any errors
      if (hasError) {
        alert('Please complete all required fields.');
        return;
      }

      // Prepare campaign details
      const campaignDetails = {
        email: this.email,
        campaignType: this.selectedCampaignType,
        objectives: this.selectedObjectives,
        targetAudience: this.targetAudience,
        marketingChannel:
          this.selectedMarketingChannel === 'Other'
            ? this.customMarketingChannel
            : this.selectedMarketingChannel,
        campaignDuration: this.selectedCampaignDuration,
        successMetrics: this.selectedMetrics.includes('Other')
          ? [
              ...this.selectedMetrics.filter(
                (metric) => metric !== 'Other'
              ),
              this.customMetric,
            ]
          : this.selectedMetrics,
        brandStrategy:
          this.selectedBrandStrategy === 'Other'
            ? this.customBrandStrategy
            : this.selectedBrandStrategy,
        creativeElements: this.selectedElements.includes('Other')
          ? [
              ...this.selectedElements.filter(
                (el) => el !== 'Other'
              ),
              this.customElement,
            ]
          : this.selectedElements,
          keyValue:
            this.selectedKeyValue === 'Other'
                ? this.customKeyValue
                : this.selectedKeyValue, 
        technologies: this.selectedTechnologies.includes('Other')
          ? [
              ...this.selectedTechnologies.filter(
                (tech) => tech !== 'Other'
              ),
              this.customTechnology,
            ]
          : this.selectedTechnologies,
        marketingConsistency:
          this.selectedConsistency === 'Other'
            ? this.customConsistency
            : this.selectedConsistency,
        budget: this.selectedBudget,
        previousCampaignDifference:
          this.selectedPreviousCampaignDiff === 'Other'
            ? this.customPreviousCampaignDiff
            : this.selectedPreviousCampaignDiff,
        marketTrend:
          this.selectedMarketTrend === 'Other'
            ? this.customMarketTrend
            : this.selectedMarketTrend,
        toneStyle:
          this.selectedToneStyle === 'Other'
            ? this.customToneStyle
            : this.selectedToneStyle,
        toneReason:
          this.selectedToneReason === 'Other'
            ? this.customToneReason
            : this.selectedToneReason,
      };

      // Prepare the data to send to Google Forms
      const formData = new URLSearchParams();
      
      // Map Vue.js data to Google Form entry IDs
      formData.append('entry.934550263', this.selectedCampaignType); // Campaign Type

      this.selectedObjectives.forEach(objective => {
          formData.append('entry.599150444', objective);
        });// Objectives
      formData.append('entry.376877753', this.targetAudience.age); // Age
      formData.append('entry.103915780', this.targetAudience.gender); // Gender
      formData.append('entry.485604797', this.targetAudience.householdSize); // Household Size
      formData.append('entry.22969741', this.targetAudience.maritalStatus); // Marital Status
      formData.append('entry.1001452286', this.targetAudience.familyLifecycle); // Family Lifecycle
      formData.append('entry.361194714', this.targetAudience.homeOwnership); // Home Ownership
      formData.append('entry.1669175268', this.targetAudience.income); // Income
      formData.append('entry.787220392', this.targetAudience.education); // Education
      formData.append('entry.195503636', this.targetAudience.region); // Region
      formData.append('entry.586542321', this.targetAudience.citySize); // City Size
      formData.append('entry.441556711', this.targetAudience.urbancity); // Urbanicity

      // Map other fields similarly
      formData.append('entry.1144201868', this.selectedCampaignDuration); // Campaign Duration
      formData.append('entry.1414200808', this.selectedBudget); // Budget

      if (this.selectedKeyValue === 'Other') {
        formData.append('entry.1662687644.other_option_response', this.customKeyValue);
    } else {
        formData.append('entry.1662687644', this.selectedKeyValue);
    }

    if (this.selectedPreviousCampaignDiff === 'Other') {
        formData.append('entry.377959753.other_option_response', this.customPreviousCampaignDiff);
    } else {
        formData.append('entry.377959753', this.selectedPreviousCampaignDiff);
    }


    
      if (this.selectedMarketingChannel === 'Other') {
        formData.append('entry.113966502.other_option_response', this.customMarketingChannel);
      } else {
        formData.append('entry.113966502', this.selectedMarketingChannel);
      }

      // For Marketing Trends
      if (this.selectedMarketTrend.includes('Other')) {
        formData.append('entry.871770820.other_option_response', this.customMarketTrend);
      } else {
        formData.append('entry.871770820', this.selectedMarketTrend);
      }


      // For Success Metrics
      if (this.selectedMetrics.includes('Other')) {
        formData.append('entry.1957608568.other_option_response', this.customMetric);
      } else {
        this.selectedMetrics.forEach(metric => {
          formData.append('entry.1957608568', metric);
        });
      }

      // For Brand Strategy
      if (this.selectedBrandStrategy === 'Other') {
        formData.append('entry.557701969.other_option_response', this.customBrandStrategy);
      } else {
        formData.append('entry.557701969', this.selectedBrandStrategy);
      }

      // For Creative Elements
      if (this.selectedElements.includes('Other')) {
        formData.append('entry.985729999.other_option_response', this.customElement);
      } else {
        formData.append('entry.985729999', this.selectedElements.join(', '));
      }

      // For Technologies
      if (this.selectedTechnologies.includes('Other')) {
        formData.append('entry.1418397741.other_option_response', this.customTechnology);
      } else {
        formData.append('entry.1418397741', this.selectedTechnologies.join(', '));
      }

      // For Marketing Consistency
      if (this.selectedConsistency === 'Other') {
        formData.append('entry.379253341.other_option_response', this.customConsistency);
      } else {
        formData.append('entry.379253341', this.selectedConsistency);
      }

      // For Tone Style
      if (this.selectedToneStyle === 'Other') {
        formData.append('entry.504881477.other_option_response', this.customToneStyle);
      } else {
        formData.append('entry.504881477', this.selectedToneStyle);
      }

      // For Tone Reason
      if (this.selectedToneReason === 'Other') {
        formData.append('entry.42474847.other_option_response', this.customToneReason);
      } else {
        formData.append('entry.42474847', this.selectedToneReason);
      }
      formData.append('entry.543760413', this.email); // Email from user
      // Send the data to the Google Form
      try {
            // Create the full URL with all the form data as query parameters
        const url = `https://docs.google.com/forms/d/e/1FAIpQLSfsGmAgPDCKSYnL5cHn6VE0--X3TeNoLKWYq6ZgS-AVZS5i-A/formResponse?${formData.toString()}`;
        console.log(url);

        // Open the URL in a new tab to submit the form
        window.open(url, '_blank');

        alert('Questionnaire Submitted!');
      } catch (error) {
        console.error('Error submitting the form:', error);
        alert('There was an issue submitting the form.');
      }
    }
  }
};
</script>

<style scoped>
/* General Page Layout */
.demo-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
}
.sticky-header {
  max-width: 1200px;
}
/* Title Styling */
.title {
  font-size: 2em;
  margin-bottom: 40px;
  color: #381e72;
  text-align: center;
  margin-top: 15%;
}

/* Question Section Styling */
.question-section {
  margin-bottom: 50px;
  margin-top: 5%;
}

.question-section h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #381e72;
}

.question-section h2.error {
  color: red;
}

/* Dropdown Group Styling */
.dropdown-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.dropdown-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.dropdown-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #381e72;
  background-color: #381e72;
  color: white;
  border-radius: 5px;
}
.dropdown-group select.placeholder {
  color: white;
}

/* Button Container */
.question-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.question-buttons button {
  padding: 10px 20px;
  background-color: #381e72;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1 1 calc(33% - 30px);
  max-width: calc(33% - 30px);
  margin-bottom: 10px;
  text-align: center;
}

.question-buttons button.selected {
  background-color: #f4633a;
}

.question-buttons button:hover:not(.selected) {
  background-color: #573b89;
}

/* Other Input Styling */
.other-input {
  width: 100%;
  margin-top: 15px;
}

.other-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #381e72;
  border-radius: 5px;
}

/* Submit Button */
.submit-container {
  text-align: center;
  margin-top: 50px;
}

.submit-button {
  padding: 15px 30px;
  background-color: #381e72;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #f4633a;
}

/* Error State Styling */
.error {
  color: red;
}

.error span {
  color: red;
  margin-left: 5px;
}
</style>