<template>
    <section class="how-it-works">
      <h2>How It Works</h2>
      <div class="steps-container">
        <div class="step" v-for="(step, index) in steps" :key="index">
          <div class="step-number">{{ index + 1 }}</div>
          <div class="step-content">
            <h3>{{ step.title }}</h3>
            <p>{{ step.description }}</p>
          </div>
        </div>
      </div>
      <div class="demo-button-container">
        <router-link to="/demo">
          <button class="demo-button">DEMO</button>
        </router-link>
    </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        steps: [
          { title: 'Answer', description: 'Answer 12 Campaign Questionnaire according to your Campaign Type.' },
          { title: 'Select', description: 'Select from 60+ different audience categories.' },
          { title: 'Analyze', description: 'Receive an analyzed PDF report via email.' },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .how-it-works {
    padding: 50px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .how-it-works h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #381e72; /* Matches the theme colors */
  }
  
  .steps-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .step {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    width: 250px;
    text-align: center;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .step:hover {
    transform: translateY(-10px);
  }
  
  .step-number {
    background-color: #f4633a; /* Accent color */
    color: white;
    font-size: 2em;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
  }
  
  .step-content h3 {
    font-size: 1.5em;
    color: #381e72;
    margin-bottom: 10px;
  }
  
  .step-content p {
    font-size: 1.1em;
    color: #666;
  }
  .demo-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.demo-button {
  background-color: #f4633a;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.demo-button:hover {
  background-color: #e35227;
}
  </style>