<template>
    <section class="hero-section">
      <div ref="sceneContainer" class="three-container"></div>
      <div class="hero-text">
        <h1>Welcome to Mosaique</h1>
        <p>Answer campaign questions, select audience segments, and receive detailed analysis.</p>
      </div>
    </section>
  </template>
  
  <script>
  
  export default {
    name: 'HeroSectionComponent',
  };
  </script>
  
  <style scoped>
  .hero-section {
    background: linear-gradient(to right, #381e72, #f4633a);
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .three-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .hero-text {
    position: absolute;
    text-align: center;
    color: white;
    z-index: 2;
  }
  
  .hero-text h1 {
    font-size: 3em;
    margin-bottom: 10px;
  }
  
  .hero-text p {
    font-size: 1.5em;
    max-width: 600px;
    margin: 0 auto;
  }
  </style>